@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

$main-black: #000;
$accent-black: #222;
$main-grey: rgba(238, 238, 238, 0.6);
$dark-grey: #999;
$second-grey: rgba(200, 200, 200, 0.6);
$darken: rgba(0, 0, 0, 0.1);

$blue-start: #00a7b7;
$blue-end: #007aa2;

$green-start: #37a847;
$green-end: #006c3b;

$yellow-start: #f6d43f;
$yellow-end: #e7ac5d;

$yellow-offset: 80deg;
$green-offset: $yellow-offset + 120deg;
$blue-offset: $yellow-offset - 120deg;

$move-offset: 180deg;

@keyframes spin {
  0% {
    transform: rotate(0deg);
    stroke: $blue-start;
    stroke-dasharray: 0% 3000%;
  }
  25% {
    stroke: $green-start;
  }
  50% {
    stroke: $yellow-start;
    transform: rotate(540deg);
    stroke-dasharray: 314% 3000%;
  }
  75% {
    stroke: $green-start;
  }
  100% {
    transform: rotate(1080deg);
    stroke: $blue-start;
    stroke-dasharray: 0% 3000%;
  }
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(calc(-140rem + 100vw), 0, 0); /* The image width */
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

#rekviz_table {
  td {
    padding: 0.25rem;
    border-bottom: 0.0625rem solid #ddd;
    &:first-child {
      max-width: 7rem;
    }
  }
}
.gradient {
  background: linear-gradient(135deg, $main-grey, $second-grey);
  &.run {
    background: linear-gradient(135deg, $green-start, $green-end);
  }
  &.ride {
    background: linear-gradient(135deg, $yellow-start, $yellow-end);
  }
  &.ski {
    background: linear-gradient(135deg, $blue-start, $blue-end);
  }
  &.multi {
    background: linear-gradient(
      135deg,
      $yellow-start,
      $green-start,
      $blue-start
    );
  }
}
.color {
  &.run {
    color: $green-end;
  }
  &.ride {
    color: $yellow-end;
  }
  &.ski {
    color: $blue-end;
  }
  &.multi {
    color: $blue-end;
  }
}

.fill {
  &.run {
    fill: $green-end;
  }
  &.ride {
    fill: $yellow-end;
  }
  &.ski {
    fill: $blue-end;
  }
  &.multi {
    fill: $blue-end;
  }
}
.stroke {
  &.run {
    stroke: $green-end;
  }
  &.ride {
    stroke: $yellow-end;
  }
  &.ski {
    stroke: $blue-end;
  }
  &.multi {
    stroke: $blue-end;
  }
}

.ReactModalPortal {
  .activity-form {
    .form-holder {
      overflow: auto;
      img {
        width: 90%;
        max-width: 15rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid #eaeaea;
        margin: 0.5rem auto;
        display: block;
      }
    }
  }
}

.athlete-linker {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
}
.button-assign-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .button-assign {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    color: #fff;
    padding: 0rem 1rem;
    border-radius: 0.25rem;
    margin: 0.25rem auto;

    .preloader-holder {
      padding: 0;
      margin-right: 1rem;

      svg {
        fill: none;
      }
    }
    svg {
      color: #fff;
      fill: #fff;
      margin-right: 0.5rem;
    }
  }
}
.activity-form {
  .form-holder {
    margin: 1rem 0.25rem;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);

    .react-date-picker__wrapper,
    .react-time-picker__wrapper {
      border: thin solid #d7d7d7;
      padding: 0.25rem;
      border-radius: 0.25rem;
    }

    .react-time-picker__clock,
    .react-calendar {
      border: thin solid #d7d7d7;
      border-radius: 0.25rem;
    }

    .form-title {
      font-size: 1.5rem;
      color: #fff;
      padding: 0.5rem;
    }
    .form-content {
      padding: 0.5rem;

      .kind-select__menu,
      .kind-select__control {
        .button-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;
          box-shadow: none !important;
          width: auto !important;

          .text {
            font-size: 1.2rem !important;
            margin: 0 !important;
          }

          .icon-holder {
            flex-shrink: 0;
            margin: 0 !important;
            margin-right: 0.5rem !important;
            width: 1.5rem !important;
            height: 1.5rem !important;
            svg {
              width: 100%;
              height: 100%;
            }
          }

          &.active {
            background: transparent;

            .icon-holder {
              svg {
                fill: #000 !important;
              }
            }
            .text {
              color: #000 !important;
              font-size: 1.2rem !important;
            }
          }
        }
      }

      p.field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;

        .checkbox {
          margin-right: 0.5rem;
        }
      }

      .field-holder {
        .title {
          font-size: 0.85rem;
          margin-top: 0.5rem;
        }
        input.field {
          user-select: auto;
          padding: 0.25rem;
          font-size: 1rem;
          line-height: 1.5rem;
          margin: 0.125rem;
          border: 0.0625rem solid #e4e4e4;
          border-radius: 0.125rem;
          font-family: Helvetica, Arial, sans-serif;
          width: 100%;
        }
      }

      .button {
        font-size: 1.5rem;
        color: #fff;
        border-radius: 0.25rem;
        padding: 0.5rem;
        text-align: center;
      }
    }
  }
}
.container {
  > div {
    position: relative;
    z-index: 2;
  }
  .center {
    .button-link {
      .home-button {
        font-size: 1.5rem;
        color: #fff;
        padding: 0.5rem;
        text-align: center;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0.25rem;
        cursor: pointer;

        svg {
          margin-right: 0.25rem;
        }
      }
    }
  }
}
#outer-container {
  .info-page {
    &.main {
      .wrapper {
        overflow-y: auto;
        overflow-x: hidden;
      }
      .misc {
        position: absolute;
        top: 11rem;
        width: 10rem;
        left: 50%;
        margin-left: -10rem;
      }
      .lang-holder {
        position: absolute;
        top: 9.8rem;
        left: 50%;
        padding-left: 6rem;
        display: flex;
        flex-direction: row;
        .lang {
          margin: 0.125rem;
          &.run {
            font-weight: 800;
          }
        }
      }
      .container {
        height: auto;
        overflow: visible;

        .contacts {
          max-width: 40rem;
          margin: 0 auto;
          font-size: 1.2rem;
          text-align: center;
          flex-direction: row;
          display: flex;
          padding: 0.5rem;
        }
        .marketing-text {
          max-width: 40rem;
          margin: 4rem auto;
          font-size: 0.85rem;
          // text-align: justify;
          padding: 2rem;
          z-index: 2;
          line-height: 1.25rem;
          position: relative;
        }
        .marketing-text-half {
          max-width: 40rem;
          margin: 0 auto;
          margin-top: 0rem;
          font-size: 0.75rem;
          text-align: justify;
          padding: 2rem;
          z-index: 2;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 2rem;
            left: 0;
            right: 0;
            bottom: 0rem;
            // transform: skew(5deg, 5deg);
            border-radius: 0.25rem;
            z-index: 1;
            background: #fff;
          }

          > * {
            z-index: 2;
            position: relative;
          }
          h4 {
            padding-left: 5rem;
            font-size: 1.25rem;
            margin-top: 2.5rem;
          }
          .compete-holder {
            margin: 2rem;
            display: flex;
            flex-wrap: wrap;
            &.padding {
              padding-left: 2rem;
            }
          }
          .compete-by {
            display: flex;
            flex-direction: row;
            color: #fff;
            padding: 1rem;
            margin: 0.25rem;
            border-radius: 0.25rem;
            .text {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 9rem;
              text-align: left;
            }
            .icon {
              font-size: 2rem;
              margin-right: 0.5rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .header-text {
          text-align: center;
          max-width: 40rem;
          margin: 0 auto;
          margin-top: 2rem;
          font-size: 2rem;
        }
        .activity-types {
          max-width: 40rem;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2rem;
          align-items: flex-start;
          justify-content: center;

          .button-card {
            position: relative;
            z-index: 2;
            width: 7rem;
            height: 7rem;
            display: flex;
            border-radius: 0.125rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .icon-holder {
              margin: 0 auto;
            }
            .text {
              font-size: 1rem;
              white-space: inherit;
              text-align: center;
            }
          }
        }
        h3 {
          position: relative;
          z-index: 4;
          height: 5rem;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          &.color {
            &.white {
              color: #fff;
            }
          }
        }
        .pre-header {
          position: absolute;
          /* border-width: 0.5rem; */
          /* opacity: 0.5; */
          /* border-style: solid; */
          top: -1rem;
          width: 100%;
          height: 18rem;
          transform: skew(5deg, 5deg);
          border-radius: 0.5rem;
          z-index: 0;
        }
        .pre-motivation {
          position: absolute;
          top: -5.5rem;
          width: 100%;
          height: 29rem;
          transform: skew(5deg, 5deg);
          border-radius: 0.5rem;
          z-index: 0;
        }
        .pre-types {
          position: absolute;
          top: -6rem;
          width: 100%;
          bottom: -2rem;
          // transform: skew(5deg, 5deg);
          border-radius: 0.5rem;
          z-index: 0;
          background: #fff;
        }
        .pre-footer {
          position: absolute;
          bottom: 0rem;
          width: 100%;
          height: 45rem;
          transform: skew(5deg, 5deg);
          border-radius: 0.5rem;
          z-index: 0;
        }

        .motivation-container {
          margin-bottom: 5rem;
        }
        .cards {
          overflow: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          scroll-snap-type: x mandatory;
          margin: 0 auto;
          padding-top: 2rem;
          position: relative;
          z-index: 2;
          transform: skew(5deg, 5deg);

          .motivation-card {
            position: relative;
            z-index: 2;
            border-radius: 0.125rem;
            flex-shrink: 0;
            width: 20rem;
            scroll-snap-align: center;
            scroll-margin-left: 0.5rem;
            overflow: hidden;
            margin: 0.5rem;
            // box-shadow: 0 0 0.25rem -0.125rem rgba(0, 0, 0, 0.5);

            .card-image {
              height: 15rem;
              border-radius: 0.25rem;
              overflow: hidden;
              position: relative;

              .card-image-render {
                position: absolute;
                top: -1rem;
                bottom: -1rem;
                left: -1rem;
                right: -1rem;
                background-position: center;
                background-size: cover;
                transform: skew(-5deg, -5deg);
              }
            }
            .card-text {
              font-size: 1.2rem;
              line-height: 1.5rem;
              margin: 1rem;
              color: #fff;
              font-weight: 800;
              transform: skew(-5deg, -5deg);
            }
          }
        }
        .photos {
          margin: 2rem auto;
          padding: 0;
          margin-top: 0;
          /* display: grid; */
          /* grid-template-columns: repeat(3, 1fr); */
          /* grid-auto-rows: 40vw; */
          /* grid-column-gap: 0.125rem; */
          /* grid-row-gap: 0.125rem; */
          overflow: hidden;
          transform: skewY(5deg);
          .photos-holder {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            animation: slide 20s ease-in-out;
            animation-iteration-count: infinite;
          }
          .photo-item {
            background-position: center;
            background-size: cover;
            flex-shrink: 0;
            background-repeat: no-repeat;
            height: 20rem;
            transform: skewY(-5deg);
            width: 20rem;
            margin-top: -2rem;
            margin-bottom: -2rem;

            &#photo-1 {
              grid-column: 1 / 3;
            }
            &#photo-2 {
              grid-column: 3 / 3;
              grid-row: 2 / 4;
            }
            &#photo-6 {
              grid-column: 1 / 1;
              grid-row: 3 / 3;
            }
            &#photo-9 {
              background-position: top;
            }
            &#photo-10 {
              grid-column: 2 / 4;
              grid-row: 3 / 3;
              background-position: top;
            }
          }
        }
      }
      .button-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 6rem;
        flex-direction: column;
        z-index: 2;
      }
      .policy-link-holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 20rem;
        left: 50%;
        margin-left: -10rem;
        position: absolute;
        top: 21.5rem;

        a {
          .login-button {
            padding: 0 0.5rem;
            width: 11rem;
            margin: 0.125rem;
          }
        }
      }
      .button {
        font-size: 1.5rem;
        color: #fff;
        border-radius: 0.25rem;
        padding: 0.5rem;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0.25rem;
        font-size: 1rem;
        flex: 1;
        font-weight: 100;
        text-decoration: none;
        cursor: pointer;
        svg {
          margin: 0 0.25rem;
        }

        &.go-login {
          width: 15rem;
        }
      }
      .club-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        margin-bottom: 3rem;

        &:before {
          content: "";
          position: absolute;
          width: 20rem;
          height: 25rem;
          top: -14rem;
          background-color: #fff;
          transform: skewY(5deg);
          border-radius: 0.5rem;
        }

        .logo-holder {
          z-index: 2;
          .logo {
            height: 4rem;
            background-position: center;
            background-size: cover;
            margin: 0;
            padding: 0;
          }
          .icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            svg {
              flex-shrink: 0;
              width: 5rem;
              height: 5rem;
              margin: 0.25rem;

              &#run {
                .main-stop {
                  stop-color: $green-start;
                }
                .alt-stop {
                  stop-color: $green-end;
                }
                path {
                  fill: url(#gradient-run);
                }
              }
              &#ride {
                .main-stop {
                  stop-color: $yellow-start;
                }
                .alt-stop {
                  stop-color: $yellow-end;
                }
                path {
                  fill: url(#gradient-ride);
                }
              }
              &#ski {
                .main-stop {
                  stop-color: $blue-start;
                }
                .alt-stop {
                  stop-color: $blue-end;
                }
                path {
                  fill: url(#gradient-ski);
                }
              }
            }
          }
        }
      }
    }
  }
}

.athlete-search {
  display: flex;
  user-select: auto;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
  min-height: 22rem;

  .athlete-search__input {
    user-select: auto;
    input {
      user-select: auto;
    }
  }
  .athlete-search__menu {
    z-index: 10 !important;
  }

  .athlete {
    .photo {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .pic {
      width: 10rem;
      height: 10rem;
      border-radius: 10rem;
      background-size: cover;
    }
  }
  .athlete-holder {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      padding: 0.25rem 2rem;
    }
  }
}

.promocodes {
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .athlete-promocodes {
    max-width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;

    .code-card {
      width: 16rem;
      flex-shrink: 0;
      margin: 0.5rem;

      .title {
        padding: 0.5rem;
        font-weight: 400;
        color: white;
      }

      .description {
        font-size: 0.875rem;
        padding: 0.5rem;
      }

      .code {
        font-size: 1rem;
        font-weight: 800;
        padding: 0.5rem;
        margin: 0.5rem;
        border: 0.125rem solid;
        border-radius: 0.25rem;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s linear;
        user-select: text;

        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}

.statistics {
  font-size: 1.5rem;
  font-weight: 100;
  display: flex;
  max-width: 100%;
  overflow: hidden;

  .athlete-statistics {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .distance {
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 0.0625rem solid;
      border-radius: 2rem;
      padding: 0.25rem;
      margin: 0.25rem;

      svg {
        margin-right: 0.25rem;
      }
    }
  }
}
.archievements {
  padding-top: 1rem;
  max-width: 100%;
  justify-content: center;

  .cards {
    display: flex;
    max-width: 100%;
    overflow: auto;
    flex-wrap: nowrap;
    align-items: center;
    .nav-link {
      flex-shrink: 0;
      .button-card {
        width: 4rem !important;
        height: 4rem;
        flex-shrink: 0;
        padding: 0.5rem;
        border-radius: 4rem !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .image-background {
          width: 3rem;
          height: 3rem;
          background-position: center;
          background-size: contain;
        }

        svg {
          width: 3rem;
          height: 3rem;
          stroke: none;
          fill: white;
        }
      }
    }
  }
}
.radar-chart {
  margin-top: 2rem;
  height: 20rem;
  width: 20rem;
  padding: 2rem;
  position: relative;

  .chart-icon-holder {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    margin: -1.25rem 0 0 -1.25rem;
    // border-width: 0.125rem;
    // border-style: solid;
    // border-radius: 50%;
    overflow: hidden;

    .button-card {
      margin: 0 !important;
      flex-shrink: 0;
      padding: 0;
      &.icon-only {
        width: 2.375rem !important;
        height: 2.375rem !important;
        margin: 0;
        padding: 0;
        overflow: hidden;

        .icon-holder {
          margin: 0rem !important;
          width: 2rem !important;
          height: 2rem !important;
        }
      }
    }
  }
}

.react-activity-calendar {
  margin-bottom: 1rem;
  width: 100% !important;
  flex: 1;
}
.activities-list {
  overflow: hidden;
  max-width: 100%;
}
.activities-calendar {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-width: 100%;

  .calendar {
    flex-direction: column;
    display: flex;
    max-width: 100%;

    .calendar-footer {
      padding: 0.25rem 0.5rem;
      display: flex;
      flex-direction: row;
      font-size: 0.75rem;

      .total-count {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .legend {
        font-weight: 100;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .day-render-background {
          width: 1rem;
          height: 1rem;
          margin: 0.125rem;
          border-radius: 50%;

          &.level-0 {
            opacity: 0;
          }
          &.level-1 {
            opacity: 0.25;
          }
          &.level-2 {
            opacity: 0.5;
          }
          &.level-3 {
            opacity: 0.75;
          }
          &.level-4 {
            opacity: 1;
          }
        }
      }
    }
    .calendar-body {
      flex-direction: row;
      display: flex;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      scroll-snap-type: x mandatory;

      .after {
        width: 0.25rem !important;
        background: transparent;
        flex-shrink: 0;
        height: 100%;
      }

      .month {
        scroll-snap-align: center;
        scroll-margin-left: 0.25rem;
        flex-direction: column;
        display: flex;
        margin: 1rem 0.25rem 0.25rem 0.25rem;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        overflow: hidden;
        flex-shrink: 0;

        .month-name {
          display: flex;
          font-size: 1.75rem;
          font-weight: 100;
          padding: 0.5rem;

          align-items: center;
          justify-content: center;
        }

        .month-header {
          display: flex;
          flex-direction: row;
          height: 3rem;
          width: 21rem;

          .day-name {
            font-size: 1rem;
            color: $main-black;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            opacity: 0.5;

            &.weekend {
              color: red;
            }
          }
        }
        .month-body {
          display: flex;
          flex-direction: column;
          padding: 0.25rem;

          .week-render {
            display: flex;
            flex-direction: row;
            position: relative;
            height: 3rem;
            width: 21rem;

            .day-render {
              position: absolute;
              font-size: 1rem;
              width: 3rem;
              height: 3rem;
              display: flex;
              align-items: center;
              justify-content: center;

              &.day-0 {
                left: 0rem;
                top: 0;
              }
              &.day-1 {
                left: 3rem;
                top: 0;
              }
              &.day-2 {
                left: 6rem;
                top: 0;
              }
              &.day-3 {
                left: 9rem;
                top: 0;
              }
              &.day-4 {
                left: 12rem;
                top: 0;
              }
              &.day-5 {
                left: 15rem;
                top: 0;
              }
              &.day-6 {
                left: 18rem;
                top: 0;
              }
              &.day-7 {
                left: 0rem;
                top: 0;
              }

              .day-render-count {
                position: absolute;
                top: 0rem;
                right: 0rem;
                padding: 0.125rem;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                font-size: 0.75rem;
                line-height: 0.75rem;
                min-height: 1rem;
                border-radius: 50%;
                min-width: 1rem;
                color: #fff;
                box-shadow: 0 0.0625rem 0.125rem -0.0625rem black;
              }

              .day-render-number {
                position: absolute;
                top: 0.25rem;
                bottom: 0.25rem;
                left: 0.25rem;
                right: 0.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                text-shadow: 0 0 0.125rem #fff;

                font-size: 1rem;
                color: $main-black;

                &.border {
                  cursor: pointer;
                  // color: white;
                  border-width: 0.125rem;
                  border-style: solid;
                  border-radius: 50%;
                }
              }
              .day-render-background {
                position: absolute;
                top: 0.25rem;
                bottom: 0.25rem;
                left: 0.25rem;
                right: 0.25rem;
                border-radius: 50%;
                z-index: 0;

                &.level-0 {
                  opacity: 0;
                }
                &.level-1 {
                  opacity: 0.25;
                }
                &.level-2 {
                  opacity: 0.5;
                }
                &.level-3 {
                  opacity: 0.75;
                }
                &.level-4 {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

svg.react-activity-calendar__calendar {
  margin: 0 auto;
  display: block;
}
.border {
  &.run {
    border-color: $green-end;
  }
  &.ride {
    border-color: $yellow-end;
  }
  &.ski {
    border-color: $blue-end;
  }
  &.multi {
    border-color: $yellow-end;
  }
}
.preloader {
  stroke-dasharray: 150% 100%;
  border: none !important;
  background-position: center;
  background-size: contain;
  fill: none;
  svg {
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
input {
  user-select: auto;
}
a,
a:visited,
a:hover,
a:active,
button {
  &.button-link {
    min-width: 11rem;
    margin-top: 0.25rem;
    color: inherit;
    outline: none;
    text-decoration: none;
  }

  &.nav-link {
    color: inherit;
    outline: none;
    text-decoration: none;
    transition: all linear 0.3s;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }
}
html {
  height: 100vh;
  overflow: hidden;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #ededed;
  font-family: "Helvetica", "Roboto", sans-serif;
}
svg {
  flex-shrink: 0;
}
.ReactModal__Overlay {
  z-index: 20;
  overflow: hidden;

  .ReactModal__Content {
    padding: 0 !important;
    overflow: hidden !important;
    box-shadow: 0 0.0625rem 0.125rem -0.0625rem black;
    border: none !important;
    top: 4rem !important;
    left: 1rem !important;
    right: 1rem !important;
    bottom: 2rem !important;
  }
}
.select-holder {
  padding: 0;
  display: flex;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  flex-direction: column;
  position: relative;
  margin-top: 0.5rem;
  z-index: 8;

  .select-comp {
    min-width: 17rem;

    .select-comp__control {
      border: none;
      border-radius: 0.5rem;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 2rem;
  border-radius: 2rem;
  overflow: hidden;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        padding: 0 2rem 0 0.5rem;

        &:before {
          left: calc(100% - 1.75rem);
        }
      }
    }
  }
  .switch-body-render {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      flex-shrink: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: $second-grey;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #fff;
    text-align: center;
    padding: 0 0.5rem 0 2rem;
    font-size: 1rem;
    line-height: 2rem;
    -webkit-text-fill-color: #fff;
    box-shadow: inset 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);

    &.round {
      border-radius: 3rem;
      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      left: 0.25rem;
      right: auto;
      bottom: 0.25rem;
      background-color: white;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
    }
  }
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
}
.athlete-profile {
  > .name {
    padding: 1.5rem 0.5rem 0rem;
  }
}
.athlete,
.athlete-profile {
  .name {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 900;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &.male {
    .photo {
      .pic {
        svg {
          .st0 {
            display: none;
          }
          .st1 {
            display: inline;
            fill: #8d6e63;
          }
          .st2 {
            display: inline;
            fill: #ffccbc;
          }
          .st3 {
            display: inline;
            fill: #fbe9e7;
          }
          .st4 {
            display: inline;
          }
          .st5 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 14;
            stroke-miterlimit: 10;
          }
          .st6 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 21;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st7 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 8;
            stroke-miterlimit: 10;
          }
          .st8 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 8;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st9 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 23;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st10 {
            fill: #ffffff;
          }
          .st11 {
            fill: #5d4037;
          }
          .st12 {
            fill: #00bcd4;
          }
          .st13 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 4;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .st14 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .st15 {
            fill: #795548;
          }
          .st16 {
            display: inline;
            fill: #5d4037;
          }
          .st17 {
            display: inline;
            fill: #795548;
          }
          .st18 {
            display: inline;
            fill: #ffd600;
          }
          .st19 {
            display: inline;
            fill: #bdbdbd;
          }
          .st20 {
            fill: none;
            stroke: #ffffff;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
          .st21 {
            fill: #4e342e;
          }
          .st22 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st23 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
          .st24 {
            fill: #8d6e63;
          }
          .st25 {
            fill: #ffccbc;
          }
          .st26 {
            fill: #fbe9e7;
          }
          .st27 {
            fill: #ffd600;
          }
          .st28 {
            fill: #bdbdbd;
          }
        }
      }
    }
  }
  &.female {
    z-index: 2;
    .photo {
      .pic {
        svg {
          bottom: -17%;
          height: 120%;
          width: 120%;
          left: -10%;

          &.unknown-sex {
            position: relative;
            display: block;
            color: #fff;
            width: 100%;
            margin: 0;
            padding: 0;
            bottom: auto;
            left: auto;
            height: 100%;
          }

          .st0 {
            fill: #8d6e63;
          }
          .st1 {
            fill: #ffccbc;
          }
          .st2 {
            fill: #fbe9e7;
          }
          .st3 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 14;
            stroke-miterlimit: 10;
          }
          .st4 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 21;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st5 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 8;
            stroke-miterlimit: 10;
          }
          .st6 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 8;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st7 {
            fill: none;
            stroke: #9e9e9e5c;
            stroke-width: 23;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st8 {
            fill: #ffffff;
          }
          .st9 {
            fill: #5d4037;
          }
          .st10 {
            fill: #00bcd4;
          }
          .st11 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 4;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .st12 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .st13 {
            fill: #795548;
          }
          .st14 {
            fill: #ffd600;
          }
          .st15 {
            fill: #bdbdbd;
          }
          .st16 {
            fill: none;
            stroke: #ffffff;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
          .st17 {
            fill: #4e342e;
          }
          .st18 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-miterlimit: 10;
          }
          .st19 {
            fill: none;
            stroke: #4e342e;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
        }
      }
    }
  }
}
.transition-group {
  flex: 1;
  position: relative;
  z-index: 1;

  .fade-enter {
    .page {
      opacity: 0;
    }
    &-active {
      .page {
        transition: opacity 400ms ease-in;
        opacity: 1;
      }
    }
  }
}
.rating-name {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0.5rem;
  font-weight: 800;
}
.preloader-holder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  height: 100%;
  width: 100%;

  .preloader {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border-width: 0.25rem;
    border-style: solid;
  }
}
// =======================================================================
// =========================== burger menu section =======================
// =======================================================================
#outer-container {
  height: 100%;
  width: 100%;

  #page-wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;

    .wrapper {
      flex: 1;
      position: relative;
    }
  }
  .printables {
    .title {
      border-bottom: 0.0625rem solid #dedede;
      padding: 0.5rem;
    }
    .buttons {
      display: flex;
      flex: 1;
      flex-direction: column;
      .button {
        &.gradient {
          color: #fff;
          width: auto;
          margin: 0.25rem;
          display: block;
          padding: 0.5rem;
          border-radius: 0.25rem;
          width: 15rem;
          justify-content: center;
          text-decoration: none;
          cursor: pointer;
          height: 1.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          box-shadow: 0 0 0.25rem -0.125rem rgba(0, 0, 0, 0.5);
          svg {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
  .competitions-widget-holder {
    margin: 0;
  }
  .challenge-card {
    .button-card {
      width: 2rem;
      height: 2rem;
      margin: -0.125rem;
      margin-right: 0.5rem;
      border-radius: 0.25rem;
      background: none;
      box-shadow: none;

      .icon-holder {
        margin: 0.125rem;
        width: 1.75rem;
        height: 1.75rem;
      }

      .text {
        display: none;
      }
    }
  }
  .button-card {
    margin: 0.25rem;
    color: $accent-black;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.3);
    width: 7.5rem;
    flex-shrink: 0;

    &.icon-only {
      width: 3rem;
      height: 3rem;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon-holder {
        margin: 0rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .text {
      color: #222;
      font-size: 0.65rem;
      font-weight: 100;
      margin: 0.5rem;
      margin-bottom: 0.25rem;
      white-space: nowrap;
      text-align: right;
    }
    .icon-holder {
      margin: 0.5rem;
      width: 3rem;
      height: 3rem;

      svg {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
      }
    }
    &#run,
    &#threadmill,
    &#exercycle {
      .icon-holder {
        transform: rotateY(180deg);
      }
    }
    &.active {
      .text {
        color: #fff;
      }
      .icon-holder {
        svg {
          fill: #fff;
        }
      }
    }
  }

  .bm-burger-button {
    position: fixed;
    width: 1.4rem;
    height: 1.1rem;
    right: 1.2rem;
    top: 1rem;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
    border-radius: 0.0625rem;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #fff;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 5rem;
    width: 5rem;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  .banner {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .multi-switch-holder {
    position: relative;
    padding: 0.5rem;
    margin: -0.5rem;

    .stats-holder {
      .preloader-holder {
        padding: 0.5rem !important;
      }
    }

    .athlete {
      background: none;
      .nav-link {
        background: none;
        .photo {
          display: flex;
          .pic {
            flex: 1;
            margin: -0.125rem;
            background-size: cover;
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
    .cards {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      position: relative;
      z-index: 2;
      padding: 0.25rem;
      margin: 0 -0.5rem;
      scroll-snap-type: x mandatory;
      background-color: #fff;

      &:after {
        content: "";
        border-right: 0.25rem solid transparent;
      }
      .button-card {
        scroll-snap-align: center;
        scroll-margin-left: 0.25rem;
        margin: 0.25rem;
        color: $accent-black;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.3);
        width: 7.5rem;
        flex-shrink: 0;

        .text {
          color: #222;
          font-size: 0.65rem;
          font-weight: 100;
          margin: 0.5rem;
          margin-bottom: 0.25rem;
          white-space: nowrap;
          text-align: right;
        }
        .icon-holder {
          margin: 0.5rem;
          width: 3rem;
          height: 3rem;

          svg {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
          }
        }
        &.active {
          .text {
            color: #fff;
          }
          .icon-holder {
            svg {
              fill: #fff;
            }
          }
        }
      }
    }

    .combined-controll-holder {
      margin: 0 -0.5rem;
      .combined-controll {
        background-color: rgb(222, 222, 222);
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        .left-side {
          flex-direction: row;
          flex-wrap: wrap;

          .left-side-line {
            flex-shrink: 0;
            border-radius: 0;
            overflow: visible;
            margin: 0.0625rem 0.0625rem 0 0;
            margin-bottom: 0.0625rem;

            &:first-child {
              margin-left: 0.0625rem;
            }
            .season-holder {
              color: $main-black;
              .seasons {
                .season {
                  color: $main-black;
                  padding: 0 0.5rem;
                }
                svg {
                  stroke: $main-black;
                  fill: $main-black;
                }
              }
            }
          }
        }
      }
    }
    .buttons {
      position: relative;
    }
    .stats-holder {
      position: relative;
      display: flex;
      z-index: 2;
      margin: 1rem 0.5rem;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .stats {
        flex: 1;
        font-size: 1.5rem;

        .time,
        .distance {
          color: #fff !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            color: inherit !important;
            margin-right: 0.25rem;
          }
        }
      }
      .buttons {
        position: relative;
        .nav-link {
          .button {
            &.athlete {
              background-color: transparent !important;
              box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
    .back-holder {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      overflow: hidden;
      bottom: 0.5rem;

      .back {
        position: absolute;
        left: -0.5rem;
        right: -0.5rem;
        top: -0.5rem;
        bottom: -0.5rem;
        background-image: url(/images/cycling.webp);
        background-position: center;
        background-size: cover;
        filter: blur(0.125rem);
      }

      &.run {
        .back {
          background-image: url(/images/run.webp);
        }
      }
      &.walk {
        .back {
          background-image: url(/images/walk.webp);
        }
      }
      &.treadmill {
        .back {
          background-image: url(/images/treadmill.webp);
        }
      }
      &.exercycle {
        .back {
          background-image: url(/images/exercycle.webp);
        }
      }
      &.ski {
        .back {
          background-image: url(/images/ski.webp);
        }
      }
      &.pool {
        .back {
          background-image: url(/images/pool.webp);
        }
      }
      &.swim {
        .back {
          background-image: url(/images/swim.webp);
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  /* General sidebar styles */
  &.ride {
    .combined-controll,
    .group-container {
      .group-button {
        &.active {
          color: #fff;
        }
      }
    }
  }
  &.run {
    .combined-controll,
    .group-container {
      .group-button {
        &.active {
          color: #fff;
        }
      }
    }
  }
  &.ski {
    .combined-controll,
    .group-container {
      .group-button {
        &.active {
          color: #fff;
        }
      }
    }
  }
  .bm-menu {
    padding: 1.5rem 1.5rem 0;
    font-size: 1.15rem;
    width: 100%;
  }

  /* Morph shape necessary with bubble or elastic */
  // .bm-morph-shape {
  //   fill: #fff;
  // }

  /* Wrapper for item list */
  .bm-item-list {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    padding: 0.25rem;
    flex-shrink: 0;
    &.spacer {
      flex: 1;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

// =======================================================================
// ======================= end burger menu section =======================
// =======================================================================
.nav-link {
  &.link {
    padding: 0;
    margin: 0.25rem;

    &:hover {
      padding: 0;
      margin: 0.25rem;
    }
  }
}
.menu-item {
  margin: 0.5rem 1rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 100;
  font-size: 1.5rem;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &.user {
    svg {
      width: 2rem;
      height: 2rem;
    }
    .role {
      border-top: 0.0625rem solid #fff;
      font-size: 1rem;
    }
  }
  .items {
    position: absolute;
    top: 100%;
    display: none;
  }
}

.logo {
  margin: 0 0.5rem;
  flex-shrink: 0;
  padding: 0.5rem;
  min-width: 8rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-image: url(/assets/vm.svg);
  z-index: 5;
}
.container {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  overflow: hidden;

  .sub-switch {
    line-height: 2.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 0.25rem;
    }
  }

  .user-form {
    $border: 0.125rem;

    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 4rem auto;
    max-width: 19rem;
    border-radius: 0.5rem;
    padding: $border;
    border: $border solid transparent;
    background-clip: padding-box;
    box-sizing: border-box;
    box-shadow: 0 1.5rem 0.75rem -0.75rem rgba(0, 0, 0, 0.3);

    .before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
    }

    .form-holder {
      .form-title {
        font-size: 1.2rem;
        color: #fff;
        padding: 1rem 0rem;
        border-radius: 0.25rem 0.25rem 0 0;
        text-align: center;
      }
      .form-content {
        padding: 2rem;
        background: #fff;
        border-radius: 0 0 0.25rem 0.25rem;
        .form-text {
          color: #555;
        }
        .button {
          padding: 1rem;
          border-radius: 0.5rem;
          color: #fff;
          text-align: center;
          box-shadow: 0 0 0.25rem -0.125rem rgba(0, 0, 0, 0.5);
          transition: 0.3s linear all;
          cursor: pointer;

          &.disabled {
            color: #777;
            cursor: default;
          }

          &:active {
            box-shadow: 0 0rem 0rem 0 rgba(0, 0, 0, 0.5);
          }
        }

        .fields-holder {
          margin-top: 2rem;

          .field-holder {
            margin-top: 0.25rem;

            .title {
              font-size: 0.8rem;
            }
            input.field {
              border: 0.0625rem solid #ccc;
              border-radius: 0.25rem;
            }
            .field {
              width: 100%;
              font-size: 1.1rem;
              line-height: 2rem;
              font-family: Helvetica, Roboto, Arial, sans-serif;
              font-weight: 100;
              margin: 0.25rem 0;
              padding: 0 0.25rem 0 0.5rem;

              .checkbox {
                position: absolute;
                z-index: -1;
                opacity: 0;

                + label {
                  display: inline-flex;
                  align-items: center;
                  user-select: none;
                  p.personal-label {
                    font-size: 0.85rem;
                    line-height: 1rem;
                  }
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border: 1px solid #adb5bd;
                    border-radius: 0.25em;
                    margin-right: 0.5em;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 50% 50%;
                  }
                }

                &:checked {
                  + label {
                    &:before {
                      border: none;
                      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.run {
      .field {
        .checkbox {
          &:checked {
            + label {
              &:before {
                background-color: $green-start;
              }
            }
          }
        }
      }
    }
    &.ride {
      .field {
        .checkbox {
          &:checked {
            + label {
              &:before {
                background-color: $yellow-start;
              }
            }
          }
        }
      }
    }
    &.ski {
      .field {
        .checkbox {
          &:checked {
            + label {
              &:before {
                background-color: $blue-start;
              }
            }
          }
        }
      }
    }
  }

  .seasons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table {
    display: flex;
    flex-direction: column;
    margin: 0.5rem auto;
    max-width: 30rem;

    .title {
      color: $dark-grey;
      font-size: 1.5rem;
      font-weight: 100;
      padding: 0.5rem;
      text-align: center;
    }

    .lines {
      display: flex;
      flex: 1;
      max-width: 100%;
      flex-direction: column;

      .athlete {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.25rem;
        position: relative;
        padding-bottom: 0.25rem;

        .descr {
          display: flex;
          flex-direction: row;
          overflow: hidden;
          flex: 1;
        }

        a.nav-link {
          padding: 0;
          flex-shrink: 0;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          height: 0;
          left: 3rem;
          border-bottom: 0.0625rem solid #ddd;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }

        .number {
          font-size: 2rem;
          line-height: 2rem;
          font-weight: 800;
          width: 3rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          .shirt {
            font-size: 1.7em;
            position: relative;
            .num {
              position: absolute;
              font-size: 0.5em;
              color: #fff;
              top: 60%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &.signs2 {
            font-size: 1.5rem;
          }
          &.signs3 {
            font-size: 1.2rem;
          }
          &.signs4 {
            font-size: 0.9rem;
          }
          &.signs5 {
            font-size: 0.7rem;
          }
        }
        .rewards {
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .stars,
          .cups,
          .activities {
            margin: 0.125rem 0;
            white-space: nowrap;
            z-index: 2;
            font-size: 1rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
          }
        }
        .photo {
          margin: 0 0.25rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: flex;
          overflow: hidden;
          border-style: solid;

          .pic {
            flex: 1;
            margin: -0.125rem;
            position: relative;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;

            .preloader-holder {
              padding: 0;

              .preloader {
                width: 2rem;
                height: 2rem;
              }
            }

            svg {
              bottom: -15%;
              position: absolute;
              height: 114%;
              width: 114%;
              left: -7%;
              display: block;
            }
          }
        }
        &.male {
          .photo {
            .pic {
              svg {
                .st0 {
                  display: none;
                }
                .st1 {
                  display: inline;
                  fill: #8d6e63;
                }
                .st2 {
                  display: inline;
                  fill: #ffccbc;
                }
                .st3 {
                  display: inline;
                  fill: #fbe9e7;
                }
                .st4 {
                  display: inline;
                }
                .st5 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 14;
                  stroke-miterlimit: 10;
                }
                .st6 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 21;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st7 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 8;
                  stroke-miterlimit: 10;
                }
                .st8 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 8;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st9 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 23;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st10 {
                  fill: #ffffff;
                }
                .st11 {
                  fill: #5d4037;
                }
                .st12 {
                  fill: #00bcd4;
                }
                .st13 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 4;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
                .st14 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
                .st15 {
                  fill: #795548;
                }
                .st16 {
                  display: inline;
                  fill: #5d4037;
                }
                .st17 {
                  display: inline;
                  fill: #795548;
                }
                .st18 {
                  display: inline;
                  fill: #ffd600;
                }
                .st19 {
                  display: inline;
                  fill: #bdbdbd;
                }
                .st20 {
                  fill: none;
                  stroke: #ffffff;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                }
                .st21 {
                  fill: #4e342e;
                }
                .st22 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st23 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                }
                .st24 {
                  fill: #8d6e63;
                }
                .st25 {
                  fill: #ffccbc;
                }
                .st26 {
                  fill: #fbe9e7;
                }
                .st27 {
                  fill: #ffd600;
                }
                .st28 {
                  fill: #bdbdbd;
                }
              }
            }
          }
        }
        &.female {
          .photo {
            .pic {
              svg {
                bottom: -17%;
                height: 120%;
                width: 120%;
                left: -10%;

                .st0 {
                  fill: #8d6e63;
                }
                .st1 {
                  fill: #ffccbc;
                }
                .st2 {
                  fill: #fbe9e7;
                }
                .st3 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 14;
                  stroke-miterlimit: 10;
                }
                .st4 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 21;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st5 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 8;
                  stroke-miterlimit: 10;
                }
                .st6 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 8;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st7 {
                  fill: none;
                  stroke: #9e9e9e5c;
                  stroke-width: 23;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st8 {
                  fill: #ffffff;
                }
                .st9 {
                  fill: #5d4037;
                }
                .st10 {
                  fill: #00bcd4;
                }
                .st11 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 4;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
                .st12 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
                .st13 {
                  fill: #795548;
                }
                .st14 {
                  fill: #ffd600;
                }
                .st15 {
                  fill: #bdbdbd;
                }
                .st16 {
                  fill: none;
                  stroke: #ffffff;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                }
                .st17 {
                  fill: #4e342e;
                }
                .st18 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                }
                .st19 {
                  fill: none;
                  stroke: #4e342e;
                  stroke-width: 6;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 10;
                }
              }
            }
          }
        }
        .text {
          flex: 1;
          margin: 0.5rem;
          margin-bottom: 0;
          overflow: hidden;
          flex-shrink: 10;
          position: relative;
          color: $main-black;
          padding-right: 1rem;

          .name {
            font-size: 1.2rem;
            font-weight: 100;
            white-space: nowrap;
          }
          .company {
            font-size: 0.7rem;
            font-weight: 100;
            white-space: nowrap;
          }
          &:after {
            content: "";
            height: 100%;
            width: 2rem;
            position: absolute;
            right: 0;
            top: 0;
            color: rgba(255, 255, 255, 0);
            background: rgba(255, 255, 255, 0);
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 1)
            );
          }
        }
        .stats {
          flex-shrink: 0;
          flex-direction: column;
          display: flex;
          .stars,
          .cups {
            font-size: 1.5rem;
            flex-shrink: 0;
            margin: 0.25rem;
            border-radius: 0.125rem;
            padding: 0.25rem;
            display: flex;
            align-items: center;
            svg {
              border-radius: 50%;
              margin-right: 0.25rem;
            }
          }

          .distance,
          .elevation,
          .time {
            flex-shrink: 0;
            margin: 0rem;
            font-size: 1rem;
            border-radius: 0.125rem;
            padding: 0.25rem;
            display: flex;
            align-items: center;
            svg {
              border-radius: 50%;
              margin-right: 0.25rem;
            }
          }
          .distance {
            svg {
              transform: rotate(180deg);
            }
          }
        }
        &.company {
          .nav-link {
            overflow: initial;
          }
          .descr {
            .text {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }

  &:hover,
  &:active,
  &.loaded {
    > .top,
    > .bottom {
      &:before,
      &:after {
        margin-left: 230px;
        transform-origin: -200px 50%;
        transition-delay: 0s;
      }
    }
    > .top {
      &:before {
        transform: rotate($yellow-offset);
      }
      &:after {
        transform: rotate($green-offset);
      }
    }

    > .bottom {
      &:before {
        transform: rotate($blue-offset);
      }
    }

    > .center {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }
}
.container {
  > .top,
  > .bottom {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 200vmax;
      height: 200vmax;
      top: 50%;
      left: 50%;
      margin-top: -100vmax;
      transform-origin: 0 50%;
      transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
      z-index: 10;
      opacity: 0.85;
      transition-delay: 0.2s;
    }
  }

  > .top {
    &:before {
      transform: rotate($yellow-offset + $move-offset);
      background: #f8b150;
      background: linear-gradient($yellow-start, $yellow-end);
    }
    &:after {
      transform: rotate($green-offset + $move-offset);
      background: #00c350;
      background: linear-gradient($green-start, $green-end);
    }
  }

  > .bottom {
    &:before {
      transform: rotate($blue-offset + $move-offset);
      background: #009de2;
      background: linear-gradient($blue-start, $blue-end);
    }
  }
}
.activity-form {
  .activities-list {
    .item-holder {
      margin-top: 0.125rem;
      .activity {
        .activity-holder {
          .date {
            .start {
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
}
.delete-screen {
  max-width: 25rem;
  border-radius: 2rem;
  border: 0.125rem solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.activities-list {
  flex-direction: column-reverse;
  display: flex;
  margin: 0.5rem -0.5rem;
  align-items: center;
  position: relative;
  padding-bottom: 1rem;
  z-index: 5;

  .item-holder {
    flex-direction: column;
    display: flex;
    width: 95%;
    box-shadow: 0 0.045em 0.25em -0.0675em rgba(0, 0, 0, 0.3);

    .activity {
      width: 100%;
      position: relative;
      flex-direction: row;
      transition: 0.3s cubic-bezier(0.31, 1.97, 0.58, 0.65) all;
      display: flex;
      align-items: center;
      background: #fff;
      min-height: 4em;
      padding: 0.25em;
      &.first {
        margin-bottom: 5em;
      }

      .fitnes-icon-holder {
        width: 3.5rem;
        border-right: 0.0625em solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
          width: 2em;
          height: 2em;
        }
        a.data-provider-link {
          background: #fff;
          text-decoration: none;
          cursor: pointer;
          border: 0.0625rem solid;
          flex-direction: row;
          display: flex;
          margin: 0.25rem auto;
          box-shadow: 0 0 0.25rem -0.125rem rgba(0, 0, 0, 0.5);
          border-radius: 0.25rem;
          padding: 0.125rem;
          font-size: 3rem;
          align-items: center;
          justify-content: center;

          &.checked {
            box-shadow: none;
            cursor: default;
          }
          .background-holder {
            flex-shrink: 0;
            min-width: 3rem;
            min-height: 1rem;
            flex: 1;
            width: auto;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.manual {
            .background-holder {
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              justify-content: center;

              &:after {
                content: "Ручной ввод";
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.5rem;
              }
            }
          }
          &.gpx {
            .background-holder {
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              justify-content: center;

              &:after {
                content: "GPX";
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.75rem;
              }
            }
          }
          &.garmin {
            .background-holder {
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              justify-content: center;

              &:after {
                content: "GARMIN";
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.75rem;
              }
            }
          }
          &.apple {
            .background-holder {
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              justify-content: center;

              &:after {
                content: "APPLE";
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.75rem;
              }
            }
          }
          &.google {
            .background-holder {
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              justify-content: center;

              &:after {
                content: "GOOGLE";
                white-space: nowrap;
                text-decoration: none;
                font-size: 0.75rem;
              }
            }
          }
          &.polar {
            .background-holder {
              background-image: url(/assets/polar_logo.svg);
            }
          }
          &.vmarafone {
            .background-holder {
              background-image: url(/assets/vm.svg);
            }
          }
        }
      }
      .expander-holder {
        width: 0em;
        border-left: 0.0625em solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        .expander-button {
          width: 2em;
          height: 2em;
        }
        svg {
          width: 2em;
          height: 2em;
        }
      }
      .activity-holder {
        flex-direction: row;
        display: flex;
        flex: 1;
        padding: 0 0 0 0.25em;

        svg {
          margin: 0 0.125rem;
          flex-shrink: 0;
        }

        .photos {
          font-size: 2.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .date {
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          flex: 1;

          .start {
            font-size: 1.5rem;
            line-height: 4rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
          }
        }
        .info {
          flex-direction: column;
          display: flex;

          .distance,
          .elevation,
          .time {
            flex: 1;
            flex-direction: row;
            display: flex;
            align-items: center;
            margin: 0.125rem;
            white-space: nowrap;
            justify-content: flex-start;
          }

          .distance {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  &.condensed {
    .activity {
      transition: 0.3s linear all;
      box-shadow: 0 0.045em 0.25em -0.0675em rgba(0, 0, 0, 0);
      transform: translate(0, 0) !important;
      &.last {
        box-shadow: 0 0.045em 0.25em -0.0675em rgba(0, 0, 0, 0.3);
      }
      &.first {
        margin-bottom: 0em;
      }
    }
  }
}

.club-holder {
  display: flex;
  flex-direction: row;
  width: 8rem;
  .clubs {
    position: absolute;
    top: 3rem;
    height: 0;
    max-height: 25rem;
    overflow: auto;
    left: 0.5rem;
    padding: 0 0.25rem 0 0.25rem;
    max-width: 19rem;
    background: #fff;
    padding: 0rem;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: inset 0 0.0625rem rgba(0, 0, 0, 0.1);

    &.visible {
      height: auto;
      padding: 0.25rem;
    }

    .club {
      padding: 0.125rem;
      padding-right: 0.5rem;
      border-radius: 0.125rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 100;

      &.current {
        color: #fff;
      }

      .club-title {
        padding: 0.25rem 0.125rem;
      }

      > .logo {
        height: 3rem;
        width: 6rem !important;
        min-width: auto;
        background-color: #fff;
        border-radius: 0.125rem;
        margin-right: 0.5rem;
        background-position: center;
        padding: 0.125rem;
        background-size: contain;
      }
      .logo-holder {
        background-color: #fff;
        border-radius: 0.125rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.photo-edit {
  overflow: hidden;
}
canvas {
  width: 5rem;
  height: 5rem;
}
.photo-upload-edit {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .file-input-holder {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .photo-edit-holder {
    background: #aaa;
    padding: 0 10%;
    overflow: auto;
    flex: 1;
    margin: 0 auto;
    position: relative;

    .ReactCrop {
      .ReactCrop__child-wrapper {
        position: relative;
        // display: flex;

        // flex: 1;
        // object-fit: contain;

        img {
          position: relative;
          height: auto;
          width: auto;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    .input-toggle {
      font-size: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
      cursor: pointer;
      transition: all linear 0.2s;
      border-width: 0.25rem;
      border-style: dashed;
      margin: 0.5rem;
      border-radius: 0.5rem;
      min-height: 50vh;
      min-width: 15rem;
      background-color: #fff;
      &:active {
        opacity: 0.7;
      }
    }
  }
  .buttons {
    flex-shrink: 0;
  }
}

.athlete-page {
  .athlete-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 0.5rem;

    .address {
      display: flex;
      font-weight: 100;
      align-items: center;
      text-align: center;
      color: $dark-grey;

      svg {
        flex-shrink: 0;
      }
    }

    .runner {
      z-index: 1;
      left: -7vh;
      position: absolute;
      top: 0;
      width: 24vh;

      path,
      polygon {
        opacity: 0.3;
      }
    }

    .number {
      z-index: 2;
      font-size: 2rem;
      font-weight: 800;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rewards {
      z-index: 2;
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .stars,
      .cups,
      .activities {
        margin: 0 1rem;
        white-space: nowrap;
        z-index: 2;
        font-size: 2.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
    }
    .photo {
      z-index: 2;
      margin: 0 0.25rem;
      width: 10rem;
      height: 10rem;
      display: flex;
      border-radius: 50%;
      overflow: hidden;
      border-style: solid;
      position: relative;

      .hover-edit {
        position: absolute;
        color: #fff;
        background: rgba(255, 255, 255, 0.3);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 4rem;
        opacity: 0;
        transition: all linear 0.2s;
        cursor: pointer;

        &:active {
          background: rgba(0, 0, 0, 0);
        }
      }

      &:hover {
        .hover-edit {
          opacity: 1;
        }
      }

      .pic {
        position: relative;
        flex: 1;
        margin: -0.125rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        box-shadow: inset 0 0 3rem 0 rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          bottom: -7%;
          position: absolute;
          height: 114%;
          width: 114%;
          left: -7%;
          display: block;

          &.unknown-sex {
            position: relative;
            display: block;
            color: #fff;
            width: 90%;
            height: 90%;
          }
        }
      }
    }
    .text {
      z-index: 2;
      flex: 1 1;
      margin: 0.5rem;
      overflow: hidden;
      flex-shrink: 10;
      position: relative;
      background: #ffffff99;
      box-shadow: 0 0 0.125rem #fff;
      max-width: 100%;

      .name {
        font-size: 2rem;
        font-weight: 100;
        white-space: normal;
      }
      .company {
        font-size: 1.3rem;
        font-weight: 100;
        white-space: normal;
      }
    }
    .stats {
      z-index: 2;
      font-size: 2.5rem;
      .distance,
      .time,
      .elevation {
        margin: 0 0.5rem;
        border-radius: 0.125rem;
        padding: 0.25rem;
        display: flex;
        align-items: center;

        svg {
          // color: #fff;
          border-radius: 50%;
          padding: 0.125rem;
          margin-right: 0.25rem;
        }
      }
      .distance {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.page {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: -3rem;
  background: #fff;
  border: 0.0625rem solid #d7d7d7;
}
.main {
  .news-widget-holder,
  .team-challenge-widget-holder,
  .competitions-widget-holder,
  .challenges-widget-holder {
    margin: 0 -0.5rem;
    margin-top: 0.5rem;
    flex-direction: column;
    display: flex;

    & > .nav-link {
      margin: 0 !important;
      padding: 0;
      background: transparent !important;
      color: $main-black !important;

      &:hover {
        color: $accent-black !important;
      }

      .name {
        padding: 0.25rem 0.5rem;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 900;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin-right: 0.25rem;
        }
      }
    }

    .news-cards-holder,
    .team-challenge-card-holder,
    .competitions-card-holder,
    .challenges-card-holder {
      scroll-snap-type: x mandatory;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 0 0.25rem;

      &:after {
        content: "";
        border-right: 0.25rem solid transparent;
      }

      .news-card,
      .challenge-card {
        margin: 0.25rem;
        box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.3);
        border-radius: 0.25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        width: 32%;
        scroll-snap-align: center;
        scroll-margin-left: 0.25rem;

        .preview {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          .nav-link {
            flex: 1;
            margin: 0 !important;
            padding: 0;
            background: transparent !important;
            color: $main-black !important;

            &:hover {
              color: $accent-black !important;
            }

            .name {
              font-weight: 100;
              font-size: 1.2rem;
              line-height: 1.3rem;
              padding: 0.5rem;

              svg {
                margin-right: 0.25rem;
              }
            }
          }
          .news-date {
            font-size: 0.8rem;
            text-align: right;
            margin: 0.125rem 0.25rem;
            position: relative;
            color: #fff;
            z-index: 1;
          }

          .before {
            content: "";
            right: -1rem;
            height: 2rem;
            position: absolute;
            bottom: -0.7rem;
            width: 8.2rem;
            border-radius: 0.25rem 0 0 0;
            transform: skew(-30deg, 0deg);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .challenge-card {
        width: 90%;
        .info {
          flex: 1;
          display: flex;
          flex-direction: column;

          .table,
          .rules-holder {
            flex: 1;
          }

          .rules-holder {
            & > .nav-link {
              top: 2.5rem;
              position: absolute;
              left: 50%;
              margin-left: -0.5rem;
            }
          }
        }
        .name {
          font-size: 1rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}

.news {
  &.page {
    .news-card {
      margin: 0.25rem;
      box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.3);
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;

      .preview {
        margin: 0.25rem 1rem;
        border-bottom: 0.0625rem solid $main-grey;

        &:last-child {
          border-bottom: none;
        }

        .nav-link {
          margin: 0 !important;
          padding: 0;
          background: transparent !important;
          color: $main-black !important;

          &:hover {
            color: $accent-black !important;
          }

          .name {
            font-weight: 100;
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.75rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            svg {
              margin-right: 0.25rem;
            }
          }
        }

        .news-date {
          color: $main-black !important;
          margin: 0;
          padding: 0;
          right: 0;
          padding-bottom: 0.25rem;
        }
      }

      img.img_block {
        width: 10;
        width: 100%;
        height: auto;
      }

      .nav-link {
        margin-bottom: 0 !important;
        .name {
          display: flex;
          flex-direction: row;
          svg {
            margin-right: 0.25rem;
          }
        }
      }
      .news-date {
        font-size: 0.8rem;
        padding-bottom: 0.5rem;
        padding-right: 0.5rem;
        text-align: right;
        color: $dark-grey;
      }
      .news-image {
        height: 10rem;
        background-color: $second-grey;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.full {
          background-size: contain;
          height: 15rem;
        }
      }

      .info {
        padding: 0.5rem 1rem;
        font-weight: 100;
        color: $main-black !important;

        img {
          max-width: 100%;
          height: auto;
        }

        hr {
          border: 0.0625rem solid $main-grey;
        }

        ul {
          li {
            margin: 0.5rem 0;
          }
        }
        ol {
          margin: 0.5rem 0;
          li {
            margin: 0;
          }
        }

        &.small {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

.main {
  .logo {
    margin: 0rem;
    flex-shrink: 0;
    background-origin: content-box;
    background-position-x: left;
    background-image: url(/assets/vm.svg);
  }
  .angle-holder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    right: 3rem;
    z-index: 1;

    .angle {
      content: "";
      display: block;
      position: absolute;
      transform-origin: 0 0;
      transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
      z-index: 10;
      opacity: 1;
      height: 3rem;
      top: 0;
      background: #fff;
      transform: skew(-30deg, 0deg);
      left: 0rem;
      z-index: 2;
      width: 9rem;
    }
  }
  .header {
    filter: drop-shadow(0 -0.0625rem 0.125rem rgba(0, 0, 0, 0.5));
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;

    .drop {
      z-index: 2;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #222;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.25rem;
      }
    }

    .logo-holder {
      height: 3rem;
      width: 6rem;
      display: flex;
      z-index: 2;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .logo {
        margin: 0rem;
        flex-shrink: 0;
        padding: 0;
        min-width: 7rem;
        height: 1.7rem;
        margin-top: 0;
        margin-bottom: -0.45rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        background-image: url(/assets/vm.svg);
      }

      .icons {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.45rem;

        * {
          height: 1.6rem;
          width: 1.6rem;
          margin: 0.0625rem;

          &#run {
            .main-stop {
              stop-color: $green-start;
            }
            .alt-stop {
              stop-color: $green-end;
            }
            path {
              fill: url(#gradient-run);
            }
          }
          &#ride {
            .main-stop {
              stop-color: $yellow-start;
            }
            .alt-stop {
              stop-color: $yellow-end;
            }
            path {
              fill: url(#gradient-ride);
            }
          }
          &#ski {
            .main-stop {
              stop-color: $blue-start;
            }
            .alt-stop {
              stop-color: $blue-end;
            }
            path {
              fill: url(#gradient-ski);
            }
          }
        }
      }
    }
    .nav-link {
      display: flex;
      padding: 0;
      box-shadow: none;
      height: 3rem;
      background: rgba(255, 255, 255, 0);

      &.sex {
        height: 3rem;
        width: 3rem;
        padding: 0.5rem;
        margin-right: 3rem;
        position: relative;
        z-index: 2;

        svg {
          width: 100%;
          height: 100%;
        }

        .button {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 50%;
          box-shadow: 0 0 0.125rem rgb(0, 0, 0);

          &.female {
            .photo {
              width: 100%;
              height: 100%;
              .pic {
                width: 100%;
                height: 100%;
                position: relative;
                svg {
                  bottom: -17%;
                  height: 120%;
                  width: 120%;
                  left: -10%;
                  position: absolute;
                }
              }
            }
          }
          &.male {
            .photo {
              width: 100%;
              height: 100%;
              .pic {
                width: 100%;
                height: 100%;
                position: relative;
                svg {
                  bottom: -5%;
                  height: 110%;
                  width: 110%;
                  left: -5%;
                  position: absolute;
                }
              }
            }
          }
        }
      }
    }
    .menu {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: flex-end;
      flex: 1;
      align-items: center;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 100;
      margin-left: 2rem;

      .log-out {
        font-size: 3rem;
        margin: auto 1rem;
        height: 3rem;
        transition: all linear 0.3s;
        cursor: pointer;

        &:hover {
          color: #ff7733;
        }
      }

      .menu-icon {
        display: none;
      }
    }
  }
  .page-warning {
    color: #f53;
    font-size: 1.2rem;
    text-align: center;
    margin: 2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  .footer {
    height: 3rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    transition: none;
    filter: drop-shadow(0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5));

    .angle-holder {
      .angle {
        top: -800%;
        transform: rotate(65deg);
        left: 0;
      }
    }

    .news-link {
      right: 13.25rem;
      height: 2rem;
      position: absolute;
      bottom: 0;
      width: 4rem;
      border-radius: 0.5rem 0 0 0;
      transform: skew(-30deg, 0deg);
      z-index: 1;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      a.nav-link {
        color: #808080;
        font-size: 1.25rem;
        cursor: pointer;
        transform: skew(30deg, 0deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
    }

    .left {
      right: 10.25rem;
      height: 2rem;
      position: absolute;
      bottom: 0;
      width: 4rem;
      border-radius: 0.5rem 0 0 0;
      transform: skew(-30deg, 0deg);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      a.link {
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
        transform: skew(30deg, 0deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
    }
    .middle {
      right: 7.25rem;
      height: 2rem;
      position: absolute;
      bottom: 0;
      width: 4rem;
      border-radius: 0.5rem 0 0 0;
      transform: skew(-30deg, 0deg);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      a.link {
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
        transform: skew(30deg, 0deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
    }
    .right {
      z-index: 3;
      width: 10rem;
      height: 3rem;
      position: absolute;
      right: -2rem;
      border-radius: 0.75rem 0 0 0;
      transform: skew(-30deg, 0deg);

      .powered {
        left: 1rem;
        right: auto;
        transform: skew(30deg, 0deg);
      }
    }
  }
  .bottom-links {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;

    .nav-link {
      padding: 0 0.5rem;
      border-radius: 0.125rem;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
      display: block;
      margin: 1rem 0.25rem;
      color: white;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2.5rem;
        font-size: 1.2rem;
        font-weight: 100;

        svg {
          margin-right: 0.25rem;
        }
      }
    }
  }

  .challenges.page,
  .news.page {
    height: auto;
  }
  .challenge-card,
  .news-card {
    .bar-holder {
      height: 1.5rem;
      display: flex;
      margin: 0;
      border-radius: 0.125rem;
      overflow: hidden;
      background: $second-grey;

      &.status-holder {
        border: none;
        margin-bottom: 0.25rem;
        .button {
          margin: 0;
          justify-content: flex-end;
          border-radius: 0;
          padding: 0 0.25rem 0 0;

          .percent-value {
            padding-right: 0.25rem;
          }
        }
      }

      .percent-holder {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .percent-value {
          padding-left: 0.5rem;
        }
      }
    }

    .info {
      max-width: 50rem;

      .table {
        margin: 0 0.25rem;
        max-width: none;
      }
    }
    .name {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.25rem;
      text-align: left;
    }

    margin: 1rem 0;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    font-weight: 100;
    flex-direction: column;

    .competitive {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 2rem;
      padding: 0.5rem;
      border-top: 0.0625rem solid $second-grey;
      svg {
        margin-right: 0.25rem;
      }
    }

    .rules-holder {
      position: relative;

      .athlete {
        top: -1.5rem;
        margin-left: -1.5rem;
        left: 50%;
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;
        overflow: hidden;
        position: absolute;

        &.female {
          .photo {
            .pic {
              svg {
                position: relative;
                bottom: -17%;
                height: 120%;
                width: 120%;
                left: -10%;
              }
            }
          }
        }
      }

      .conditions {
        &:empty {
          display: none;
        }
        margin: 1rem;
        border-radius: 0.25rem;
        padding: 1rem;
        background: $main-grey;
        padding-top: 1.5rem;

        .rule {
          padding: 0.5rem 0;
          margin: 0;
          border-top: 0.0625rem solid $second-grey;
          color: $accent-black;
          display: flex;
          font-weight: 100;
          align-items: center;

          svg {
            margin-right: 0.5rem;
          }

          &:first-child {
            border: none;
          }
        }
      }
    }

    .status-holder {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-top: 0.0625rem solid $second-grey;

      .button {
        text-align: center;
        line-height: 2.5rem;
        padding: 0 0.5rem 0 0.25rem;
        margin: 0.25rem;
        color: white;
        border-radius: 0.25rem;
        cursor: pointer;
        box-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s linear all;

        svg {
          margin: 0.25rem;
          &.rotating {
            animation: spin 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
          }
        }
      }
      .info-button {
        text-align: center;
        line-height: 2.5rem;
        padding: 0 0.5rem 0 0.25rem;
        margin: 0.25rem;
        color: $dark-grey;
        border-radius: 0.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s linear all;

        svg {
          margin: 0.25rem;
          &.rotating {
            animation: spin 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
          }
        }
      }
    }

    .time-last {
      color: $dark-grey;
      font-size: 1.1rem;
      line-height: 1.5rem;
      text-align: center;
      display: flex;
      margin-bottom: 0.5rem;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0.25rem;
      }
    }

    .star-holder {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      border-radius: 0 0 0 0.25rem;
      padding: 0.25rem;
      position: relative;

      &:after {
        // content: "";
        height: 70%;
        width: 0.125rem;
        border-radius: 1rem;
        background: $darken;
        right: 0.125rem;
        top: 15%;
        position: absolute;
      }
    }
    .points-holder {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      flex-direction: column;
      border-radius: 0 0 0 0.25rem;
      padding: 0.25rem;
      position: relative;

      svg {
        margin-right: 0.125rem;
      }
    }
    .penalty,
    .reward {
      color: $dark-grey;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 0.5rem;

      svg {
        margin-right: 0.125rem;
      }
    }

    .reward {
      .value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // color: $green-start;
      }
    }
    .penalty {
      .value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .nav-link {
      display: block;
      font-size: 1.5rem;
      line-height: 2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      font-weight: 100;
      text-align: center;
      border-radius: 0.25rem 0.25rem 0 0;
      color: #fff;
      &.multisport-competitions {
        flex-wrap: wrap;
        justify-content: center;
        .multisport-kinds {
          display: flex;
          min-width: 90%;
          justify-content: center;
          padding-bottom: 0.25rem;
          margin-bottom: 0.25rem;
          border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.3);
        }
        .name {
          text-align: center;
          justify-content: center;
        }
      }

      .participants {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0.25rem;
        font-size: 1.25rem;
        svg {
          margin-right: 0.125rem;
        }
      }

      svg {
        margin-right: 0.25rem;
      }

      .pic {
        svg {
          margin: 0;
        }
      }
    }

    > .nav-link {
      display: flex;
    }

    .nav-link {
      color: #fff;
    }

    .athlete {
      .nav-link {
        margin: 0;
        padding: 0;
        line-height: initial;
        background: transparent !important;
      }
      .name {
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .page-header {
    color: $dark-grey;
    font-size: 1.5rem;
    line-height: 2rem;

    text-align: center;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 0.25rem;
    }
  }

  .modal-title {
    line-height: 2rem;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 100;
    text-align: center;
  }

  &.seasons-list {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;
    max-height: 100%;

    .button {
      font-size: 1rem;
      line-height: 2rem;
      font-weight: 100;
      border-radius: 0.25rem;
      cursor: pointer;
      margin: 0.25rem;
      text-align: center;
      color: #fff;
    }

    .seasons {
      flex: 1;
      overflow: auto;

      .season {
        margin: 0.5rem;
        border-bottom: 0.0625rem solid $second-grey;
        padding: 0.5rem;

        &.current {
          border-radius: 0.25rem;
        }

        .season-title {
          font-size: 1.2rem;
          &:first-letter {
            text-transform: capitalize;
          }
        }
        .season-time-last {
          font-size: 0.7rem;
          color: $second-grey;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  .sub-switch,
  .number,
  .season,
  .distance,
  .time,
  .elevation,
  .stars,
  .cups,
  .activities-list,
  .activities {
    &.current {
      color: #fff;
      -webkit-text-fill-color: #fff;
      .season-time-last {
        color: #fff;
        -webkit-text-fill-color: #fff;
      }
    }
  }

  .container {
    overflow: auto;
    height: auto;
    height: 100%;
    padding: 0 0 2rem 0;

    .combined-controll-holder {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;

      .combined-controll {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        background-color: $second-grey;
        overflow: hidden;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);

        .left-side {
          display: flex;
          flex: 1;
          flex-direction: column;
          overflow: hidden;

          .left-side-line {
            background-color: #fff;
            min-height: 1.75rem;
            display: flex;
            flex: 1;
            align-items: center;
            margin: 0;
            margin-bottom: 0.0625rem;
            overflow: hidden;

            &:last-child {
              border-bottom: none;
              margin-bottom: 0;
              border-radius: 0 0 0 0.435rem;
            }

            &:first-child {
              border-radius: 0.435rem 0 0 0;
            }

            svg {
              flex-shrink: 0;
            }

            .season-holder {
              max-width: 100%;
              display: flex;
              flex: 1;
              .seasons {
                display: flex;
                flex: 1;
                font-size: 2.5rem;
                line-height: 2.5rem;
                font-weight: 100;

                svg {
                  width: 2rem;
                  height: 2rem;
                  margin: 0.25rem;
                }

                .season {
                  margin: 0;
                  flex: 1;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  overflow: hidden;

                  .season-title {
                    flex: 1;
                    font-size: 1rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 0;
                    &:first-letter {
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }

        .right-side {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0;
          margin-left: 0.0625rem;
          background-color: #fff;
          flex-shrink: 0;
          border-radius: 0 0.435rem 0.435rem 0;
        }
      }
    }
    .buttons {
      margin: 0;
      padding: 0;
      .button {
        border: none;
      }
      .athlete {
        overflow: hidden;
        box-shadow: none;
        background: none;
        display: flex;

        .photo {
          border-width: 0.125rem;
          border-style: solid;
          flex: 1;
          display: flex;
          border-radius: 50%;
          overflow: hidden;

          .pic {
            flex: 1;
            margin: -0.125rem;
            position: relative;

            svg {
              bottom: -15%;
              position: absolute;
              height: 114%;
              width: 114%;
              left: -7%;
              &.spectator {
                width: auto;
                height: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                fill: #fff;
                padding: 0.25rem;
              }
            }
          }
        }

        &.female {
          svg {
            bottom: -17%;
            height: 120%;
            width: 120%;
            left: -10%;
          }
        }
      }
    }

    .group-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 0.5rem;
      background-color: $second-grey;
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);

      .group-button {
        flex: 1;
        background: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 2.25rem;
        padding: 0.25rem 0.75rem 0.25rem 0.5rem;
        margin-right: 0.0625rem;

        .title {
          margin-left: 0.25rem;
        }

        &:first-child {
          border-radius: 0.435rem 0 0 0.435rem;
        }
        &:last-child {
          margin-right: 0;
          border-radius: 0 0.435rem 0.435rem 0;
        }

        &.active {
          background: transparent;
          color: #fff !important;
          margin-right: 0;
          margin-left: -0.0625rem;
        }
      }
    }

    .season-time-last {
      color: $dark-grey;
      font-size: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0.25rem;
      }
    }

    .seasons {
      svg {
        padding: 0.25rem;
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }

    .season {
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0.25rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      // cursor: pointer;
      text-align: center;
      z-index: 3;
      transition: 0.2s linear all;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);

      &.current {
        cursor: default;
        &:hover {
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      z-index: 2;

      &.group-holder {
        margin: 0;
        padding: 0;
      }

      #run {
        path {
          fill: url(#gradient-run);
        }
      }
      #ride {
        path {
          fill: url(#gradient-ride);
        }
      }
      #ski {
        path {
          fill: url(#gradient-ski);
        }
      }
      #run,
      #ride,
      #ski {
        .main-stop {
          stop-color: $dark-grey;
        }
        .alt-stop {
          stop-color: $dark-grey;
        }
      }

      a.nav-link {
        padding: 0 !important;
        border-radius: 50%;
        &.current {
          padding: 0 !important;
        }
      }
      .button {
        cursor: pointer;
        transition: 0.3s linear all;
        width: 3rem;
        height: 3rem;
        margin: 0.5rem;
        flex-shrink: 0;
        border-radius: 50%;
        box-sizing: content-box;
        transition: none;

        &.back {
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 1.5rem;
          height: 2rem;
          width: 2rem;
          color: $dark-grey;
          margin: 0.5rem;
        }

        &.sex {
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 1.5rem;
          height: 2rem;
          width: 2rem;
          color: white;
          position: relative;
          border-radius: 0.5rem;
          transform: rotate(45deg);

          svg {
            transform: rotate(-45deg);
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-radius: 0.5rem;
            border: 0.125rem solid #fff;
            opacity: 0.5;
          }
        }

        &.active {
          border-width: 0.5rem;
          width: 3rem;
          height: 3rem;
          background-color: #fff;
          border: none;

          #run {
            .main-stop {
              stop-color: $green-start;
            }
            .alt-stop {
              stop-color: $green-end;
            }
          }
          #ride {
            .main-stop {
              stop-color: $yellow-start;
            }
            .alt-stop {
              stop-color: $yellow-end;
            }
          }
          #ski {
            .main-stop {
              stop-color: $blue-start;
            }
            .alt-stop {
              stop-color: $blue-end;
            }
          }
        }
      }
    }
  }
}
.powered {
  position: fixed;
  bottom: 0;
  right: 1rem;
  width: 6rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0.7rem 0.5rem;
  border-radius: 1rem;
  z-index: 100;
  background-color: #fff;
  background-size: contain;
  background-image: url(/assets/vm.svg);
  // background-image: url(/assets/api_logo_pwrdBy_strava_stack_white.png);
}
svg.fake-login {
  border-width: 0.125rem;
  border-radius: 5rem;
  border-style: solid;
  vertical-align: top;
  margin: 0 0.5rem;
  cursor: pointer;
  &:hover {
    color: $green-start;
  }
}

#appleid-signin {
  width: 11rem;
  height: 2.5rem;
  position: relative;
  > div {
    > div {
      position: relative;
      z-index: 2;
      &:nth-child(2) {
        position: absolute;
        z-index: 1;
      }
    }
  }
}

.login-button {
  border-radius: 0.35rem;
  white-space: nowrap;
  height: 2.5rem;
  cursor: pointer;
  overflow: hidden;
  background-color: #fff;
  background-position: center;

  &.presentation {
    flex-direction: row;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .p-icon {
      width: 5rem;
      font-size: 1.7rem;
      height: 2.5rem;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .p-text {
      display: block;
      text-align: left;
    }
  }

  &.starva {
    background-image: url(/assets/btn_strava_connectwith_light.png);
  }
  &.yandex {
    flex-direction: row;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    .y-icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    .y-text {
      padding: 0 0.5rem;
      // color: #000;
      flex: 1;
      display: block;
      text-align: left;
    }
  }
  &.google {
    flex-direction: row;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    .g-icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    .g-text {
      padding: 0 0.5rem;
      flex: 1;
      display: block;
      text-align: left;
    }
  }
}
.policy-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  svg {
    margin-right: 0.5rem;
  }
}

.container {
  > .center {
    position: absolute;
    width: 30rem;
    height: 30rem;
    top: 50%;
    left: 50%;
    margin-left: -15rem;
    margin-top: -16.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    transition-delay: 0s;
    color: #666 !important;

    svg.fake-login {
      border-width: 0.125rem;
      border-radius: 5rem;
      border-style: solid;
      vertical-align: top;
      margin: 0 0.5rem;
      cursor: pointer;
      &:hover {
        color: $green-start;
      }
    }

    .logo-holder {
      margin-bottom: 1rem;
    }

    .caption {
      margin: 1rem 0 0.25rem 0;
    }
    .header {
      margin-bottom: 0rem;
    }
    .button-link {
      text-decoration: none;
    }
    .login-button {
      border-radius: 0.2rem;
      width: 11rem;
      height: 2.5rem;
      margin: 0.25rem;
      cursor: pointer;
      overflow: hidden;
      background-color: #fff;
      background-position: center;
      box-shadow: 0 0.0625rem 0.25rem -0.125rem rgba(0, 0, 0, 0.7);
      &.presentation {
        flex-direction: row;
        background-origin: content-box;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        .p-icon {
          width: 5rem;
          font-size: 1.7rem;
          height: 2.5rem;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        .p-text {
          display: block;
          text-align: left;
        }
      }

      &.starva {
        background-image: url(/assets/btn_strava_connectwith_light.png);
      }
      &.yandex {
        flex-direction: row;
        background-origin: content-box;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .y-icon {
          width: 2.5rem;
          height: 2.5rem;
        }

        .y-text {
          padding: 0 0.5rem;
          // color: #000;
          flex: 1;
          display: block;
          text-align: left;
        }
      }
      &.google {
        flex-direction: row;
        background-origin: content-box;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .g-icon {
          width: 2.5rem;
          height: 2.5rem;
        }

        .g-text {
          padding: 0 0.5rem;
          flex: 1;
          display: block;
          text-align: left;
        }
      }
    }

    .logo-holder {
      .icons {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;

        * {
          height: 3rem;
          width: 3rem;
          margin: 0.125rem;

          &#run {
            .main-stop {
              stop-color: $green-start;
            }
            .alt-stop {
              stop-color: $green-end;
            }
            path {
              fill: url(#gradient-run);
            }
          }
          &#ride {
            .main-stop {
              stop-color: $yellow-start;
            }
            .alt-stop {
              stop-color: $yellow-end;
            }
            path {
              fill: url(#gradient-ride);
            }
          }
          &#ski {
            .main-stop {
              stop-color: $blue-start;
            }
            .alt-stop {
              stop-color: $blue-end;
            }
            path {
              fill: url(#gradient-ski);
            }
          }
        }
      }
      .logo {
        margin: 0rem;
        flex-shrink: 0;
        padding: 0rem;
        min-width: 8rem;
        height: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-origin: content-box;
        background-image: url(/assets/vm.svg);

        .item {
          border: 0.15rem solid #ff0000;
          border-radius: 0.25rem;
          height: 3rem;
          width: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0.25rem;
          transform: skew(-10deg, 0deg);

          &.green {
            border-color: $green-start;
          }
          &.yellow {
            border-color: $yellow-start;
          }
          &.blue {
            border-color: $blue-start;
          }
        }
      }

      .name {
        font-weight: 100;
        transform: skew(-15deg, 0deg);
        text-align: right;

        &.club {
          text-align: left;
        }
      }
    }
    input {
      width: 100%;
      padding: 1rem;
      margin: 0.25rem;
      border-radius: 0.5rem;
      border: 0.0625rem solid $main-grey;
      font-family: inherit;
      outline: none;
    }
  }
}
.settings {
  .user-form {
    max-width: 25rem;
    box-shadow: 0 0.5rem 1.5rem -0.25rem rgba(0, 0, 0, 0.3);

    .form-holder {
      flex: 1;
      .form-text {
        padding: 1rem;
      }
      .fields-holder {
        margin-top: 0 !important;
      }
    }

    a.data-provider-link {
      width: 16rem;
      height: 3rem;
      text-decoration: none;
      cursor: pointer;
      border: 0.125rem solid;
      flex-direction: row;
      display: flex;
      margin: 0.5rem auto;
      box-shadow: 0 0 0.25rem -0.125rem rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
      padding: 0.5rem;
      font-size: 3rem;
      align-items: center;
      justify-content: center;

      &.checked {
        box-shadow: none;
        cursor: default;
      }
      .background-holder {
        height: 2rem;
        flex-shrink: 0;
        flex: 1;
        width: auto;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.manual {
        .background-holder {
          position: relative;
          display: flex;
          align-items: center;
          text-decoration: none;
          justify-content: center;

          &:after {
            content: "Ручной ввод";
            white-space: nowrap;
            text-decoration: none;
            font-size: 1.75rem;
          }
        }
      }
      &.polar {
        .background-holder {
          background-image: url(/assets/polar_logo.svg);
        }
      }
    }
  }
}
@media (max-width: 780px) {
  .activities-calendar {
    .calendar {
      .calendar-body {
        .month {
          .month-header {
            height: 12vw;
            width: 84vw;

            .day-name {
              width: 12vw;
              height: 12vw;
            }
          }
          .month-body {
            .week-render {
              height: 12vw;
              width: 84vw;
              .day-render {
                width: 12vw;
                height: 12vw;
                &.day-0 {
                  left: 0vw;
                }
                &.day-1 {
                  left: 12vw;
                }
                &.day-2 {
                  left: 24vw;
                }
                &.day-3 {
                  left: 36vw;
                }
                &.day-4 {
                  left: 48vw;
                }
                &.day-5 {
                  left: 60vw;
                }
                &.day-6 {
                  left: 72vw;
                }
                &.day-7 {
                  left: 0vw;
                }
                .day-render-number {
                  font-size: 3vw;
                }
              }
            }
          }
        }
      }
    }
  }

  .main {
    .news-widget-holder {
      .news-cards-holder {
        .news-card {
          width: 32%;
        }
      }
    }
  }
  .multi-switch-holder {
    .cards {
      box-shadow: inset 0 0 0.125rem rgba(0, 0, 0, 0.3);
      .button-card {
        width: 18vw !important;
      }
    }
    .combined-controll-holder {
      .combined-controll {
        .left-side {
          .left-side-line {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .main {
    .header {
      .menu {
        justify-content: flex-end;
        .items {
          display: none;
        }
        .menu-icon {
          margin: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.5rem;
          cursor: pointer;
        }
      }
    }
  }
  .multi-switch-holder {
    .cards {
      .button-card {
        width: 21vw !important;
      }
    }
  }
}
@media (max-width: 550px) {
  .main {
    .news-widget-holder {
      .news-cards-holder {
        .news-card {
          width: 45%;
        }
      }
    }
  }
  .multi-switch-holder {
    .cards {
      .button-card {
        width: 26vw !important;
      }
    }
  }
}

@media (max-width: 460px) {
  .container {
    .table {
      .lines {
        .athlete {
          .descr {
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }
          .stats {
            flex-shrink: 0;
            flex-direction: row;
            display: flex;
          }
        }
      }
    }
  }
  .multi-switch-holder {
    .cards {
      .button-card {
        width: 31vw !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .main {
    .news-widget-holder {
      .news-cards-holder {
        .news-card {
          width: 70%;
        }
      }
    }
  }
  .container {
    .table {
      .lines {
        .athlete {
          .text {
            .name {
              font-size: 1rem;
            }
          }
          .stats {
            .distance,
            .elevation,
            .time {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  .multi-switch-holder {
    .cards {
      .button-card {
        width: 40vw !important;
      }
    }
  }
}
